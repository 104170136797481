import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import SEO from '../components/seo';

const Title = styled.h1`
  font-size: 2.4em;
  text-align: center;
  font-weight: 400;
  font-family: 'Sorts Mill Goudy', serif;
`;

const Text = styled.div`
  font-size: 1.4em;
  text-indent: 2em;
  text-align: justify;
`;

const Work = ({ data }) => {
  const html = () => ({ __html: data.contentfulWork.docxFile.localFile.childDocx.content });

  return (
    <>
      <SEO title={data.contentfulWork.title} description={`G.S.M. Nui's ${data.contentfulWork.title}`} />
      <Title>{data.contentfulWork.title}</Title>
      <Text dangerouslySetInnerHTML={html()} />
    </>
  );
};

Work.propTypes = {
  data: PropTypes.shape({
    contentfulWork: PropTypes.shape({
      title: PropTypes.string.isRequired,
      docxFile: PropTypes.shape({
        localFile: PropTypes.shape({
          childDocx: PropTypes.shape({
            content: PropTypes.string.isRequired,
          }),
        }),
      }),
    }),
  }).isRequired,
};

export default Work;

export const query = graphql`
  query Work($slug: String!) {
    contentfulWork(fields: { slug: { eq: $slug } }) {
      title
      docxFile {
        localFile {
          childDocx {
            content
          }
        }
      }
    }
  }
`;
